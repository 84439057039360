const singleSwipe = () => {
    if (!window.isTouch) return;
    const preview = document.querySelector('[js-hook-preview]'); if (!preview) return

    let touchstartX = 0
    let touchendX = 0

    function checkDirection() {
        if (touchendX < touchstartX && touchstartX - touchendX > 100) {
            const nextButton = document.querySelector('[js-hook-preview-next]');
            window.swipeActive = true
            nextButton.click()
        }
        if (touchendX > touchstartX && touchendX - touchstartX > 100) {
            const previousButton = document.querySelector('[js-hook-preview-prev]');
            window.swipeActive = true
            previousButton.click()
        }
    }

    const onStart = (e) => {
        touchstartX = e.changedTouches[0].screenX
    }

    const onEnd = (e) => {
        touchendX = e.changedTouches[0].screenX
        checkDirection()
    }

    document.addEventListener('touchstart', onStart)
    document.addEventListener('touchend', onEnd)
}

export default singleSwipe

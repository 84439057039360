import gsap from "gsap";

const menu = () => {
    const hamburger = document.querySelector('[js-hook-hamburger]'); if (!hamburger) return
    const menuItems = document.querySelectorAll('.menu-item'); if (!menuItems) return
    
    const CLASS_ACTIVE = 'is--active'

    let menuOpen = false

    hamburger.addEventListener('click', () => toggleMenu());

    [...menuItems].forEach((menuItem) => {
        menuItem.addEventListener('click', () => toggleMenu())
    })

    const tl = gsap.timeline({
        defaults: {
            duration: 1
        }
    })

    tl.paused(true)
    tl.set('[js-hook-menu]', {pointerEvents: 'auto'})
    tl.to('[js-hook-menu-overlay-front]', {y: 0, duration: 1, ease: 'power4.inOut'}, 0)
    tl.to('[js-hook-menu-nav] li', {autoAlpha: 1, stagger: 0.1}, '-=0.3')
    tl.from('[js-hook-menu-socials] [js-hook-social]', {autoAlpha: 0, stagger: 0.1}, '-=1')
    tl.to('[js-hook-menu-image-container]', {autoAlpha: 1, duration: 1, ease: 'power2.inOut'}, '-=1.3')

    const toggleMenu = () => {
        menuOpen = !menuOpen
        hamburger.classList.toggle(CLASS_ACTIVE)

        menuOpen ? tl.play() : tl.reverse(1.5)
    }
}

export default menu

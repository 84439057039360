import gsap from "gsap";

const singleToSingleLeave = (trigger) => {
    const preview = document.querySelector('[js-hook-preview]'); if(!preview) return

    const direction = trigger.dataset.direction

    gsap.to('[js-hook-preview-back]', {autoAlpha: 0, duration: 0.5})
    gsap.to('[js-hook-preview-play]', {autoAlpha: 0, duration: 0.5})
    gsap.to('[js-hook-preview-content]', {y: '100%', duration: 0.5})
    gsap.to('[js-hook-preview-overlay]', {autoAlpha: 0, duration: 0.5})
    return gsap.to(preview, {x: direction === 'next' ? '-100%' : '100%', duration: 1, ease: 'power4.inOut', delay: 0.5})
}

export default singleToSingleLeave

import gsap from "gsap";

const previewEnter = (timeout = 0) => {
    const preview = document.querySelector('[js-hook-preview]'); if(!preview) return
    
    gsap.set('[js-hook-preview-play-border-circle]', {strokeDashoffset: 0})
    
    const animations = () => {
        gsap.set('[js-hook-preview-play-border-circle]', {strokeDashoffset: 190, clearProps: true, delay: 0.5})
        gsap.to('[js-hook-preview-overlay]', {autoAlpha: 1, duration: 0.5})
        gsap.to('[js-hook-preview-content]', {y: 0, duration: 0.5})
        gsap.to('[js-hook-preview-play]', {autoAlpha: 1, duration: 0.5})
        gsap.to('[js-hook-preview-back]', {autoAlpha: 1, duration: 0.5})
    }

    setTimeout(() => {
        animations()
    }, timeout * 1000);

}

export default previewEnter

import gsap from "gsap";

const businessCardLeave = (done) => {
    const title = document.querySelector('[js-hook-business-card-title]'); if(!title) return

    const tl = gsap.timeline({
        onComplete: () => done(),
        reversed: true,
    })

    tl.timeScale(2);

    tl.to('[js-hook-business-card-role]', {
        autoAlpha: 0,
        duration: 1,
    })
    tl.to('[js-hook-business-card-image]', {
        autoAlpha: 0,
        ease: 'elastic.out(0.6,0.3)',
        duration: 1,
        scale: 0,
    }, '-=1.3')
    tl.to('[js-hook-business-card-info-icon]', {
        autoAlpha: 0,
        duration: 1,
        stagger: {
            from: 'left',
            amount: 0.4,
            ease: 'sine.in'
        }
    }, '-=1.2')
    tl.to('[js-hook-business-card-info-item-text]', {
        autoAlpha: 0,
        x: -10,
        duration: 1,
        stagger: {
            from: 'left',
            amount: 0.4,
            ease: 'sine.in'
        }
    }, '-=1.1')
    tl.to('[js-hook-business-card-button]', {
        autoAlpha: 0,
        ease: 'elastic.out(0.5,0.3)',
        duration: 1,
        scale: 0,
    }, '-=1')
    tl.to(title, {autoAlpha: 0, duration: 0.8}, '-=1.5')
    tl.set('[js-hook-transition]', {x: 0})
}

export default businessCardLeave

import gsap from "gsap";
import split from '../utilities/split'

const heroEnter = (element, delay = 1) => {
    const title = element.querySelector('[js-hook-hero-title]'); if(!title) return
    const overlay = element.querySelector('[js-hook-hero-overlay]'); if(!overlay) return
    
    split(title)
    
    const titleSpans = title.querySelectorAll('span span')

    const tl = gsap.timeline({
        delay,
    })  
    
    tl.set([title], {visibility: 'visible'})
    tl.from(titleSpans, {autoAlpha: 0, duration: 1, stagger: {
        from: 'left',
        amount: 0.3,
        ease: 'sine.in'
    }}, '+=0.5')
    tl.from(titleSpans, {y: 15, duration: 1, stagger: {
        from: 'left',
        amount: 0.5,
        ease: 'sine.in',
    }}, '-=1.3')
    tl.from('[js-hook-hero-button]', {
        scale: 0,
        ease: 'elastic.out(0.4,0.3)',
        duration: 1,
        onComplete: () => heroButtonScroll()
    }, '-=1')
    tl.from('[js-hook-sound] [js-hook-icon-bg]', {
        scale: 0,
        duration: 0.5,
        ease: 'power4.inOut',
        clearProps: true
    })
    tl.from('[js-hook-sound-span]', {
        scaleY: 0,
        duration: 0.8,
        stagger: 0.2,
        clearProps: true,
        onComplete: () => {
            soundScroll()
            titleScroll()
        }
    }, '-=0.8')
    tl.from('[js-hook-whatsapp] [js-hook-icon-bg]', {
        scale: 0,
        duration: 0.5,
        ease: 'power4.inOut',
        clearProps: true
    }, '-=0.4')
    tl.from('[js-hook-whatsapp-phone]', {
        opacity: 0,
        duration: 0.5,
        stagger: 0.2,
        clearProps: true
    })
    tl.from('[js-hook-whatsapp-line]', {
        strokeDashoffset: 1000,
        duration: 0.8,
        clearProps: true
    }, '-=0.5')
    tl.from('[js-hook-scroll-button-text]', {
        opacity: 0,
        y: 20,
        duration: 0.5
    }, '-=1.5')
    tl.from('[js-hook-scroll-button] [js-hook-arrow-branch]', {
        scaleY: 0,
        duration: 0.5
    }, '-=2')
    tl.from('[js-hook-scroll-button] [js-hook-arrow-left]', {
        scaleY: 0,
        duration: 0.5
    }, '-=1')
    tl.from('[js-hook-scroll-button] [js-hook-arrow-right]', {
        scaleY: 0,
        duration: 0.5,
        onComplete: () => scrollButtonScroll()
    }, '-=1')

    // Scrolltriggers
    gsap.to(overlay, {
        scrollTrigger: {
            scrub: 1,
            start: 'center center',
            end: '100% top'
        },
        backgroundColor: 'rgba(0, 0, 0, 1)'
    })

    const heroButtonScroll = () => {
        gsap.to('[js-hook-hero-button]', {
            scrollTrigger: {
                scrub: 0.5,
                start: '20% 0%',
                end: '60% 0%'
            },
            immediateRender: false,
            overwrite: 'auto',
            scale: 0.8,
            opacity: 0
        })
    }

    const soundScroll = () => {
        gsap.to('[js-hook-sound]', {
            scrollTrigger: {
                scrub: 0.5,
                start: '20% 0%',
                end: '60% 0%'
            },
            immediateRender: false,
            overwrite: 'auto',
            opacity: 0
        })
    }

    const titleScroll = () => {
        var items = gsap.utils.toArray(titleSpans);

        items.forEach((item, index) => {
            gsap.to(item, {
                scrollTrigger: {
                    scrub: 0.5,
                    start: `${items.length - index}% 0%`,
                    end: `${items.length - index + 30}% 0%`
                },
                immediateRender: false,
                overwrite: 'auto',
                opacity: 0,
                x: 20
            })
        })
    }

    const scrollButtonScroll = () => {
        const scrollButtonTl = gsap.timeline()

        scrollButtonTl.to('[js-hook-scroll-button-text]', {
            scrollTrigger: {
                scrub: 0.5,
                start: '0% 0%',
                end: '30% 0%'
            },
            immediateRender: false,
            overwrite: 'auto',
            opacity: 0,
            y: 10
        })
        .to('[js-hook-scroll-button] [js-hook-arrow-left]', {
            scrollTrigger: {
                scrub: 0.5,
                start: '0% 0%',
                end: '50% 0%'
            },
            immediateRender: false,
            overwrite: 'auto',
            scaleY: 0,
        })
        .to('[js-hook-scroll-button] [js-hook-arrow-right]', {
            scrollTrigger: {
                scrub: 0.5,
                start: '0% 0%',
                end: '50% 0%'
            },
            immediateRender: false,
            overwrite: 'auto',
            scaleY: 0,
        })
        .to('[js-hook-scroll-button] [js-hook-arrow-branch]', {
            scrollTrigger: {
                scrub: 0.5,
                start: '35% 0%',
                end: '50% 0%'
            },
            immediateRender: false,
            overwrite: 'auto',
            scaleY: 0,
        })
    }
}

export default heroEnter

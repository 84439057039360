import gsap from "gsap";

const aboutLeave = (done) => {
    const title = document.querySelector('[js-hook-about-title]'); if(!title) return

    const tl = gsap.timeline({
        onComplete: () => done(),
    })

    tl.timeScale(2);

    tl.to('[js-hook-about-socials] [js-hook-social]', {
        autoAlpha: 0,
        stagger: -0.2,
        duration: 0.5,
    })
    tl.to('[js-hook-about-section]', {
        autoAlpha: 0,
        x: -20,
        stagger: -0.3,
        duration: 0.5,
    }, '-=0.5')
    tl.to('[js-hook-about-image]', {
        autoAlpha: 0,
        ease: 'elastic.out(0.6,0.3)',
        duration: 0.5,
        scale: 0,
    }, '-=0.5')
    tl.to('[js-hook-about-text]', {
        autoAlpha: 0,
        duration: 0.5,
    }, '-=0.5')
    tl.to('[js-hook-about-title]', {
        autoAlpha: 0,
        duration: 0.5,
    }, '-=0.5')
    tl.set('[js-hook-transition]', {x: 0})
}

export default aboutLeave

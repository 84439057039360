import gsap from "gsap";

const contactLeave = (done) => {
    const title = document.querySelector('[js-hook-contact-title]'); if(!title) return

    const tl = gsap.timeline({
        onComplete: () => done()
    })

    tl.to('[js-hook-contact-socials] [js-hook-social]', {
        autoAlpha: 0,
        stagger: {
            from: 'end',
            each: 0.2,
        },
        duration: 0.6,
    })
    tl.to('[js-hook-contact-text]', {
        autoAlpha: 0,
        duration: 0.6,
    }, '-=0.6')
    tl.to('[js-hook-contact-title]', {autoAlpha: 0, duration: 0.5, stagger: {
        from: 'left',
        amount: 0.3,
        ease: 'sine.in'
    }}, '-=0.5')
    tl.set('[js-hook-transition]', {x: 0})
}

export default contactLeave

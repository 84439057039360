import gsap from "gsap";
import { previewEnter } from './'

const singleToSingleEnter = (trigger, next) => {
    const preview = next.container.querySelector('[js-hook-preview]'); if(!preview) return

    const direction = trigger.dataset.direction

    gsap.set(preview, {x: direction === 'next' ? '100%' : '-100%'})

    const tl = gsap.timeline({
        delay: 0.5,
        onComplete: () => previewEnter()
    })

    tl.to(preview, {x: 0, duration: 1, ease: 'power4.inOut'})

}

export default singleToSingleEnter

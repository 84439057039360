import gsap from "gsap";
import previewEnter from "./previewEnter";

const videoFullscreen = (trigger, next) => {
    const videoContainer = trigger.querySelector('[js-hook-portfolio-video-container]')
    const bigVideo = next.container.querySelector('[js-hook-preview-video]')
    const bigImage = next.container.querySelector('[js-hook-preview-image]')
    const videoElement = next.container.querySelector('[js-hook-preview-video]')

    gsap.set(bigImage, {opacity: 0})

    const touch = window.isTouch

    trigger.classList.add('no--clip-path')

    const viewport = videoContainer.getBoundingClientRect();
    const top = viewport.top;
    const left = viewport.left;

    gsap.set(touch ? bigImage : bigVideo, {position: 'fixed', opacity: 0, left, top, width: videoContainer.offsetWidth, height: videoContainer.offsetHeight})

   
    const toFullscreen = () => {
        const tl = gsap.timeline({
            onComplete: () => previewEnter()
        })

        if (touch) {
            tl.set(bigVideo, {autoAlpha: 0})
        }

        tl.to(touch ? bigImage : bigVideo, {opacity: 1, duration: 0.3})
          .to(touch ? bigImage : bigVideo, {width: '100%', left: 0, top: 0, height: '100%', duration: 1.2, ease: 'expo.inOut'})
    }

    if (touch) {
        toFullscreen()
    } else {
        const videoInterval = setInterval(()=>{
            if(videoElement.readyState >= 3){
                toFullscreen()
                clearInterval(videoInterval)
            }                   
        }, 10);
    }

    
}

export default videoFullscreen

const sound = () => {
    const sound = document.querySelector('[js-hook-sound'); if (!sound) return
    const video = document.querySelector('[js-hook-hero-video'); if (!video) return
    const CLASS_ACTIVE = 'sound--active'

    sound.addEventListener('click', () => handleSoundToggle())

    const handleSoundToggle = () => {
        sound.classList.toggle(CLASS_ACTIVE)
        video.muted = !video.muted
    }
   
}

export default sound

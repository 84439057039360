import gsap from "gsap";

const CLASS_FILTER_OPEN = 'filter--open'

const portfolioLeave = (done, trigger, cleared) => {
    const portfolio = document.querySelector('[js-hook-portfolio]')
    const loader = document.querySelector('[js-hook-loader]')
    const filterMobile = document.querySelector('[js-hook-filter-mobile]')

    const tl = gsap.timeline({
        onComplete: () => {
            gsap.set(portfolio, {position: 'absolute'})
            loader.classList.remove('u-hidden')
            loader.classList.add('filter--loading')

            if (cleared) {
                trigger !== filterMobile && portfolio.classList.toggle(CLASS_FILTER_OPEN)

                setTimeout(() => {
                    done()
                }, 300);
            } else {
                    done()
            }
        },
        defaults: {
            duration: 0.4,
            ease: 'power2.inOut'
        }
    })

    tl.to('[js-hook-portfolio-item]', {xPercent: -101}, 0)
            .to('[js-hook-portfolio-video-container]', {xPercent: 101}, 0)
            .to('[js-hook-portfolio-image]', {scale: 1.1, duration: 0.9, ease: 'sine'}, 0)
            .to('[js-hook-portfolio-filter-line]', {scaleX: 0}, 0)
            .to('[js-hook-portfolio-filter-cross]', {width: 0}, 0)
            .set('[js-hook-portfolio-filter]', {fontWeight: 250}, 0)
            .set(trigger, {fontWeight: 700}, 0)
}

export default portfolioLeave

import axios from 'axios'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { portfolioEnter } from './animations'
import videoHover from './video-hover'

const lazyLoadPosts = () => {
    const HIDDEN_CLASS = 'u-hidden'
    const portfolio = document.querySelector('[js-hook-portfolio]'); if (!portfolio) return
    const loader = document.querySelector('[js-hook-loader]'); if (!loader) return

    let currentPage = portfolio.dataset.page
    const maxPages = portfolio.dataset.maxPages

    if (maxPages === '1') return;

    const loadMoreScroll = ScrollTrigger.create({
        trigger: portfolio,
        start: "99% bottom",
        onToggle: self => {
            loader.classList.remove(HIDDEN_CLASS)

            setTimeout(() => {
                self.isActive && handleLoadMore()
            }, 1000);
        }
    });

    const handleLoadMore = () => {
        let currentParams = (new URL(document.location)).searchParams;
        let category = currentParams.get("c");

        let params = new URLSearchParams
        params.append('action', 'load_more_posts')
        params.append('current_page', currentPage)
        params.append('max_pages', maxPages)
        category && params.append('category_name', category)


        axios.post('/wp-admin/admin-ajax.php', params)
            .then(res => {
                const portfolioElement = document.createElement('div');
                portfolioElement.innerHTML = res.data.data;
                
                portfolio.appendChild(portfolioElement.firstElementChild)

                ScrollTrigger.refresh();
                portfolioEnter()
                videoHover()

                currentPage++
                currentPage === Number(maxPages) && loadMoreScroll.kill()
                loader.classList.add(HIDDEN_CLASS)
            })
    }
}

export default lazyLoadPosts

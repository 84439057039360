import gsap from "gsap/";

const video = () => {
    const videoComponent = document.querySelector('[js-hook-video]'); if (!videoComponent) return
    const video = videoComponent.querySelector('[js-hook-video-video]'); if (!video) return
    const previewPlay = document.querySelector('[js-hook-preview-play]');
    const heroPlay = document.querySelector('[js-hook-hero-button]');
    const cross = videoComponent.querySelector('[js-hook-video-cross]')
    const progressBar = videoComponent.querySelector('[js-hook-video-progress-bar]')
    const progress = videoComponent.querySelector('[js-hook-video-progress]')
    const fullScreenButton = videoComponent.querySelector('[js-hook-video-fullscreen]')
    const time = videoComponent.querySelector('[js-hook-video-time]')
    const playPause = videoComponent.querySelector('[js-hook-button-play-pause]')

    let idleTime = 0

    previewPlay && previewPlay.addEventListener('click', () => checkTouch())
    heroPlay && heroPlay.addEventListener('click', () => checkTouch())
    cross.addEventListener('click', () => hideVideo())
    video.addEventListener('timeupdate', () => handleVideoChange())
    playPause.addEventListener('click', () => handleVideoPausePlay())
    progress.addEventListener('click', (e) => handleProgressClick(e))
    fullScreenButton.addEventListener('click', () => handleFullScreen())
    document.addEventListener('keyup', (e) => handleKeyboardPresses(e))
    document.addEventListener('mousemove', () => handleActiveAgain())
      

    const checkTouch = () => {
        if (window.isTouch) {
            showVideoFullScreenNative()
        } else {
            showVideo()
        }
    }

    const showVideoFullScreenNative = () => {
        // Detect if webkit or not
        if(typeof window.webkitConvertPointFromNodeToPage === 'function'){
            // Webkit
            video.play()

            setTimeout(() => {
                video.webkitEnterFullScreen()
            }, 300);
            
            // video.addEventListener("webkitendfullscreen", () => {
            //     if(document.fullScreenElement || document.webkitIsFullScreen == true || document.mozFullScreen || document.msFullscreenElement ){

            //     } else {
            //         videoComponent.classList.remove('video--active-mobile')
            //     }
            // });

        } else {
            videoComponent.classList.add('video--active-mobile')
            video.requestFullscreen()
            video.play()

            document.addEventListener("fullscreenchange", () => {
                if (!document.fullscreen) {
                    videoComponent.classList.remove('video--active-mobile')
                    video.pause()
                }
            });
        }
    }

    const showVideo = () => {
        const tl = gsap.timeline()
        tl.set(videoComponent, {display: 'flex', autoAlpha: 0})
        tl.to(videoComponent, {autoAlpha: 1})
        previewPlay && previewPlay.blur()
        heroPlay && heroPlay.blur()
        video.play()
        videoComponent.classList.add('video--controls-hidden')
    }

    const hideVideo = () => {
        gsap.to(videoComponent, {autoAlpha: 0})
        video.pause()
    }

    const handleVideoChange = () => {
        const timeValue = (video.currentTime / video.duration)
        progressBar.style.transform = `scaleX(${timeValue})`

        const minutes = Math.floor(video.currentTime / 60)
        let seconds = Math.floor(video.currentTime - minutes * 60)
        if (seconds < 10) seconds = `0${seconds}`

        time.innerHTML = `${minutes}:${seconds}`
    }

    const handleProgressClick = (e) => {
        const time = (e.layerX / progress.offsetWidth) * video.duration
        video.currentTime = time
    }

    const handleFullScreen = () => {
        if(typeof window.webkitConvertPointFromNodeToPage === 'function'){
            // Webkit
            video.webkitEnterFullScreen()
        } else {
            video.requestFullscreen();
        }
    }

    const handleVideoPausePlay = () => {
        playPause.blur()
        video[video.paused ? 'play' : 'pause']()
        videoComponent.classList[video.paused ? 'add' : 'remove']('video--paused')
    }

    const handleKeyboardPresses = (e) => {
        handleActiveAgain()

        // Escape
        if (e.keyCode === 27) {
            hideVideo()
        }

        // Space
        if (e.keyCode === 32) {
            handleVideoPausePlay()
        }
    }

    const handleActiveAgain = () => {
        idleTime = 0
        videoComponent.classList.remove('video--controls-hidden')
    }

    const detectInactivity = () => {
        setInterval(() => {
            idleTime = idleTime + 1;

            if (idleTime > 1) {
                // If longer than 2 seconds inactive
                videoComponent.classList.add('video--controls-hidden')
            }
        }, 1000)
    }

    detectInactivity()
}

export default video

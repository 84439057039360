import barba from '@barba/core';
import gsap from "gsap";

import {
    videoFullscreen,
    portfolioEnter,
    heroEnter,
    splashLeave,
    previewEnter,
    workEnter,
    singleLeave,
    headerEnter,
    homeToSingleLeave,
    singleToHomeLogoEnter,
    contactEnter,
    contactLeave,
    aboutEnter,
    aboutLeave,
    singleEnter,
    singleToSingleLeave,
    singleToSingleEnter,
    businessCardEnter,
    businessCardLeave,
    portfolioLeave,
} from './animations'

import portfolioScroll from './portfolioScroll'
import singleSwipe from './singleSwipe';

const pageTransitions = () => {
    barba.init({
        transitions: [
            {
                to: { namespace: ['home'] },
                once: (data) => {
                    splashLeave()
                    portfolioEnter()
                    heroEnter(data.next.container, 1.5)
                    portfolioScroll()

                    headerEnter(2)
                },
                leave(data) {
                    console.log(data)
                    const cleared = Object.keys(data.next.url.query).length === 0
                    const done = this.async()
                    portfolioLeave(done, data.trigger, cleared)
                },
                enter: (data) => {
                    portfolioEnter()
                    heroEnter(data.next.container)
                    portfolioScroll()
                }
            },
            {
                from: { namespace: ['home'] },
                to: { namespace: ['single'] },
                sync: true,
                leave: ({current}) => homeToSingleLeave(current),
                enter: ({trigger, next}) => {
                    if (trigger instanceof HTMLElement) {
                        videoFullscreen(trigger, next)
                        !window.swipeActive && singleSwipe()
                    } else {
                        singleEnter()
                        previewEnter()
                        !window.swipeActive && singleSwipe()
                    }
                }
            },
            {
                to: { namespace: ['single'] },
                once: () => {
                    splashLeave()
                    previewEnter(1.5)
                    singleSwipe()

                    headerEnter(0.9, false)
                },
            },
            {
                from: { namespace: ['single'] },
                to: { namespace: ['home'] },
                leave: () => singleLeave(),
                enter: (data) => {
                    singleToHomeLogoEnter()
                    portfolioEnter()
                    heroEnter(data.next.container)
                    workEnter()
                },
            },
            {
                from: { namespace: ['single'] },
                to: { namespace: ['contact'] },
                leave: () => singleLeave(),
                enter: () => {
                    singleToHomeLogoEnter()
                    gsap.set('[js-hook-transition]', {x: '-100%'})
                    contactEnter()
                },
            },
            {
                from: { namespace: ['contact'] },
                to: { namespace: ['single'] },
                leave() {
                    const done = this.async()
                    gsap.to('[js-hook-logo]', {
                        clipPath: 'inset(0 100% 0 0)',
                        duration: 1,
                        ease: 'power4.inOut'
                    })
                    contactLeave(done)
                },
                enter: () => {
                    singleEnter()
                    previewEnter()
                    !window.swipeActive && singleSwipe()
                },
            },
            {
                to: { namespace: ['contact'] },
                enter: () => {
                    contactEnter()
                },
                once: () => {
                    splashLeave()
                    contactEnter(0.5)

                    headerEnter(0.5)
                },
            },
            {
                from: { namespace: ['contact'] },
                to: { namespace: ['home'] },
                leave() {
                    const done = this.async()
                    contactLeave(done)
                },
                enter: (data) => {
                    portfolioEnter()
                    heroEnter(data.next.container)
                    portfolioScroll()
                    gsap.to('[js-hook-transition]', {x: '-100%', ease: 'power4.inOut'})
                }
            },
            {
                from: { namespace: ['home'] },
                to: { namespace: ['contact'] },
                leave: () => {
                    return gsap.to('[js-hook-transition]', {x: 0, ease: 'power4.inOut'})
                },
                enter: () => {
                    gsap.set('[js-hook-transition]', {x: '-100%', ease: 'power4.inOut'})
                    contactEnter()
                }
            },
            {
                to: { namespace: ['about'] },
                enter: () => {
                    aboutEnter()
                },
                once: () => {
                    splashLeave()
                    aboutEnter(1)

                    headerEnter(0.5)
                },
            },
            {
                to: { namespace: ['business-card'] },
                enter: () => {
                    businessCardEnter()
                },
                once: () => {
                    splashLeave()
                    businessCardEnter(1)
                }
            },
            {
                from: { namespace: ['business-card'] },
                to: { namespace: ['home'] },
                leave() {
                    const done = this.async()
                    businessCardLeave(done)
                },
                enter: (data) => {
                    portfolioEnter()
                    heroEnter(data.next.container)
                    portfolioScroll()
                    gsap.to('[js-hook-transition]', {x: '-100%', ease: 'power4.inOut'})
                }
            },
            {
                from: { namespace: ['home'] },
                to: { namespace: ['business-card'] },
                leave: () => {
                    return gsap.to('[js-hook-transition]', {x: 0, ease: 'power4.inOut'})
                },
                enter: () => {
                    gsap.set('[js-hook-transition]', {x: '-100%', ease: 'power4.inOut'})
                    businessCardEnter()
                }
            },
            {
                from: { namespace: ['business-card'] },
                to: { namespace: ['about'] },
                leave() {
                    const done = this.async()
                    businessCardLeave(done)
                },
                enter: () => {
                    gsap.set('[js-hook-transition]', {x: '-100%', ease: 'power4.inOut'})
                    aboutEnter()
                }
            },
            {
                from: { namespace: ['about'] },
                to: { namespace: ['business-card'] },
                leave() {
                    const done = this.async()
                    aboutLeave(done)
                },
                enter: () => {
                    gsap.set('[js-hook-transition]', {x: '-100%', ease: 'power4.inOut'})
                    businessCardEnter()
                }
            },
            {
                from: { namespace: ['contact'] },
                to: { namespace: ['business-card'] },
                leave() {
                    const done = this.async()
                    contactLeave(done)
                },
                enter: () => {
                    gsap.set('[js-hook-transition]', {x: '-100%', ease: 'power4.inOut'})
                    businessCardEnter()
                }
            },
            {
                from: { namespace: ['business-card'] },
                to: { namespace: ['contact'] },
                leave() {
                    const done = this.async()
                    businessCardLeave(done)
                },
                enter: () => {
                    gsap.set('[js-hook-transition]', {x: '-100%', ease: 'power4.inOut'})
                    contactEnter()
                }
            },
            {
                from: { namespace: ['single'] },
                to: { namespace: ['about'] },
                leave: () => singleLeave(),
                enter: () => {
                    singleToHomeLogoEnter()
                    gsap.set('[js-hook-transition]', {x: '-100%'})
                    aboutEnter()
                },
            },
            {
                from: { namespace: ['about'] },
                to: { namespace: ['single'] },
                leave() {
                    const done = this.async()
                    gsap.to('[js-hook-logo]', {
                        clipPath: 'inset(0 100% 0 0)',
                        duration: 1,
                        ease: 'power4.inOut'
                    })
                    aboutLeave(done)
                },
                enter: () => {
                    singleEnter()
                    previewEnter()
                    !window.swipeActive && singleSwipe()
                },
            },
            {
                from: { namespace: ['about'] },
                to: { namespace: ['home'] },
                leave() {
                    const done = this.async()
                    aboutLeave(done)
                },
                enter: (data) => {
                    window.scrollTo(0,0)
                    portfolioEnter()
                    heroEnter(data.next.container)
                    portfolioScroll()
                    gsap.to('[js-hook-transition]', {x: '-100%', ease: 'power4.inOut'})
                }
            },
            {
                from: { namespace: ['home'] },
                to: { namespace: ['about'] },
                leave: () => {
                    return gsap.to('[js-hook-transition]', {x: 0, ease: 'power4.inOut'})
                },
                enter: () => {
                    window.scrollTo(0,0)
                    gsap.set('[js-hook-transition]', {x: '-100%', ease: 'power4.inOut'})
                    aboutEnter()
                }
            },
            {
                from: { namespace: ['contact'] },
                to: { namespace: ['about'] },
                leave() {
                    const done = this.async()
                    contactLeave(done)
                },
                enter: () => {
                    gsap.set('[js-hook-transition]', {x: '-100%', ease: 'power4.inOut'})
                    aboutEnter()
                }
            },
            {
                from: { namespace: ['about'] },
                to: { namespace: ['contact'] },
                leave() {
                    const done = this.async()
                    aboutLeave(done)
                },
                enter: () => {
                    gsap.set('[js-hook-transition]', {x: '-100%', ease: 'power4.inOut'})
                    contactEnter()
                }
            },
            {
                from: { namespace: ['single'] },
                to: { namespace: ['single'] },
                sync: true,
                leave: (current) => {
                    if (current.trigger instanceof HTMLElement) {
                        return singleToSingleLeave(current.trigger)
                    } else {
                        return singleLeave()
                    }
                },
                enter: ({trigger, next}) => {
                    if (trigger instanceof HTMLElement) {
                        singleToSingleEnter(trigger, next)
                    } else {
                        singleEnter(1)
                        !window.swipeActive && singleSwipe()
                        previewEnter()
                    }
                },
            },
        ]
    });
}

export default pageTransitions

import gsap from "gsap";

const singleToHomeLogoEnter = () => {
    gsap.set('[js-hook-logo]', {
        clearProps: true
    })
    gsap.from('[js-hook-logo]', {
        clipPath: 'inset(0 100% 0 0)',
        duration: 1
    })
}

export default singleToHomeLogoEnter

import gsap from "gsap";

const workEnter = () => {
    const portfolio = document.querySelector('[js-hook-portfolio]'); if(!portfolio) return
    
    window.scrollTo(0, portfolio.offsetTop);
    gsap.set('[js-hook-transition]', {x: '-100%'})
}

export default workEnter

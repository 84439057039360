import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger)

const portfolioEnter = () => {
    var items = gsap.utils.toArray('[js-hook-portfolio-grid]:last-child [js-hook-portfolio-item]');

    items.forEach((item) => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: item,
                start: '60% bottom'
            },
            defaults: {
                duration: 0.6,
                ease: 'power2.inOut'
            }
        })

        const videoContainer = item.querySelector('[js-hook-portfolio-video-container]')
        const image = item.querySelector('[js-hook-portfolio-image]')
    
        return tl.from(item, {xPercent: -101}, 0)
                 .from(videoContainer, {xPercent: 101}, 0)
                 .from(image, {scale: 1.1, duration: 0.9, ease: 'sine'}, 0)
    })
}

export default portfolioEnter

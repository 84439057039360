import gsap from "gsap";
import split from '../utilities/split'

const contactEnter = (delay = 0) => {
    const title = document.querySelector('[js-hook-contact-title]'); if(!title) return

    const tl = gsap.timeline({
        delay,
    })

    split(title)
    const titleSpans = title.querySelectorAll('span span')

    tl.set(['[js-hook-contact-title]'], {visibility: 'visible'})
    tl.from(titleSpans, {autoAlpha: 0, duration: 1.2, stagger: {
        from: 'left',
        amount: 0.3,
        ease: 'sine.in'
    }}, '+=0.5')
    tl.from(titleSpans, {y: 30, duration: 1.2, stagger: {
        from: 'left',
        amount: 1,
        ease: 'sine.in',
    }}, '-=2.3')
    tl.from('[js-hook-contact-text]', {
        autoAlpha: 0,
        duration: 1,
        clearProps: true
    }, '-=1')
    tl.from('[js-hook-contact-socials] [js-hook-social]', {
        autoAlpha: 0,
        stagger: 0.2,
        duration: 1,
        clearProps: true
    }, '-=1')
}

export default contactEnter

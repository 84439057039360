import gsap from "gsap";

const splashLeave = (splashLeaveDuration = 1) => {
    const splash = document.querySelector('[js-hook-splash]'); if(!splash) return
    
    gsap.to(splash, {clipPath: 'inset(0 0 100% 0)', duration: splashLeaveDuration, ease: 'power4.inOut', delay: 0.5})

}

export default splashLeave

import gsap from "gsap";

const headerEnter = (delay = 0, animateLogo = true) => {
    if (window.scrollY > 100) delay = 0;
    
    const tl = gsap.timeline({
        delay
    })  
    
    tl.set('[js-hook-icon-bg]', {
        transition: 'none',
    })
    tl.from('[js-hook-hamburger] [js-hook-icon-bg]', {
        scale: 0,
        duration: 0.5,
        ease: 'power4.inOut',
        clearProps: true
    }, '+=0.4')
    tl.from('[js-hook-hamburger-span]', {
        scaleX: 0,
        duration: 0.8,
        stagger: 0.2,
        clearProps: true
    }, '-=0.6')


    if (animateLogo) {
        tl.from('[js-hook-logo]', {
            clipPath: 'inset(0 100% 0 0)',
            duration: 1,
            ease: 'power4.inOut',
            clearProps: true
        }, '-=1')
    } else {
        gsap.set('[js-hook-logo]', {
            display: 'none'
        })
    }
}

export default headerEnter

const CLASS_ACTIVE = 'current-menu-item'
const CLASS_ACTIVE_OVERWRITE = 'current-menu-item--overwrite'

const updateMenuStatus = (data) => {
    const menuItems = document.querySelectorAll('.menu-item');
    const mapping = {
        single: 'Work',
        home: 'Home',
        contact: 'Contact',
        about: 'About',
    }
    
    
    menuItems.forEach((item) => {
        const anchor = item.querySelector('a')
        const slug = anchor.innerHTML
        const currentPage = data.next.namespace === 'home' && window.location.hash ? mapping.single : mapping[data.next.namespace]

        item.classList[slug === currentPage ? 'add' : 'remove'](CLASS_ACTIVE, CLASS_ACTIVE_OVERWRITE)
    })


}

export default updateMenuStatus

const menuMouseMove = () => {
    const overlay = document.querySelector('[js-hook-menu-image-overlay]'); if (!overlay) return
    const menu = document.querySelector('[js-hook-menu]'); if (!menu) return

    menu.addEventListener('mousemove', (event) => handleMouseMove(event))

    let yValue = 0
    let overlayY = 0
    let speed = 0.02;

    const animate = () => {
        let distY = -150 + (((yValue / window.innerHeight * 100) * 1.5) - overlayY) * 2;
        overlayY = overlayY + (distY * speed);

        overlay.style.transform = `translateY(${overlayY}px)`

        requestAnimationFrame(animate);
    }

    animate()

    const handleMouseMove = (event) => {
        yValue = event.clientY
    }
}

export default menuMouseMove

import gsap from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

const scrollButton = () => {
    const button = document.querySelector('[js-hook-scroll-button]'); if (!button) return

    gsap.registerPlugin(ScrollToPlugin);

    button.addEventListener('click', () => scrollToNext())

    const scrollToNext = () => {
        gsap.to(window, {duration: 1.5, scrollTo: '[js-hook-portfolio]', ease: 'power3.inOut'});
    }
}

export default scrollButton

import gsap from "gsap";
import split from '../utilities/split'

const businessCardEnter = (delay = 0) => {
    const title = document.querySelector('[js-hook-business-card-title]'); if(!title) return

    const tl = gsap.timeline({
        delay,
    })

    split(title)
    const titleSpans = title.querySelectorAll('span span')

    tl.set(['[js-hook-business-card-title]'], {visibility: 'visible'})
    tl.from(titleSpans, {autoAlpha: 0, duration: 0.8, stagger: {
        from: 'left',
        amount: 0.3,
        ease: 'sine.in'
    }}, '+=0.5')
    tl.from(titleSpans, {y: 20, duration: 1.2, stagger: {
        from: 'left',
        amount: 1,
        ease: 'sine.in',
    }}, '-=2.0')
    tl.from('[js-hook-business-card-role]', {
        autoAlpha: 0,
        duration: 1,
        clearProps: true
    }, '-=1')
    tl.from('[js-hook-business-card-image]', {
        autoAlpha: 0,
        ease: 'elastic.out(0.6,0.3)',
        duration: 1,
        scale: 0,
        clearProps: true
    }, '-=1.3')
    tl.from('[js-hook-business-card-info-icon]', {
        autoAlpha: 0,
        duration: 1,
        stagger: {
            from: 'left',
            amount: 0.4,
            ease: 'sine.in'
        }
    }, '-=1.2')
    tl.from('[js-hook-business-card-info-item-text]', {
        autoAlpha: 0,
        x: -10,
        duration: 1,
        stagger: {
            from: 'left',
            amount: 0.4,
            ease: 'sine.in'
        }
    }, '-=1.1')
    tl.from('[js-hook-business-card-button]', {
        autoAlpha: 0,
        ease: 'elastic.out(0.5,0.3)',
        duration: 1,
        scale: 0,
        clearProps: true
    }, '-=1')
}

export default businessCardEnter

import { ScrollTrigger } from "gsap/ScrollTrigger";

const whatsapp = () => {
    const whatsappEl = document.querySelector('[js-hook-whatsapp]'); if (!whatsappEl) return
    const hero = document.querySelector('[js-hook-hero]'); if (!hero) return

    const CLASS_BG = 'whatsapp--bg'

    const handleWhatsappScroll = () => {
        whatsappEl.classList.toggle(CLASS_BG)
    }

    ScrollTrigger.create({
        trigger: hero,
        start: "bottom 90%",
        endTrigger: "[js-hook-portfolio]",
        end: 'bottom top',
        onToggle: self => {
            handleWhatsappScroll()
        }
    });
}

export default whatsapp

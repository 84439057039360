const menuHover = () => {
    const nav = document.querySelectorAll('[js-hook-menu-nav] a'); if (!nav) return
    const images = document.querySelectorAll('[js-hook-menu-image]'); if (!images) return
    const CLASS_ACTIVE = 'is--active'

    nav.forEach(item => {
        item.addEventListener('mouseover', () => handleMouseOver(item))
    })

    const handleMouseOver = (item) => {
        const title = item.innerHTML

        images.forEach(image => {
            const { pageTitle } = image.dataset
            image.classList[pageTitle === title ? 'add' : 'remove'](CLASS_ACTIVE)
        })
    }
}

export default menuHover

import { tabletPortraitAndSmaller} from './utilities/breakpoints'
import { ScrollTrigger } from "gsap/ScrollTrigger";

const CLASS_FILTER_OPEN = 'filter--open'
const CLASS_FILTER_MOBILE = 'mobile--active'

const filter = () => {
    const filterButton = document.querySelector('[js-hook-portfolio-filter-button]'); if (!filterButton) return
    const filterContainer = document.querySelector('[js-hook-portfolio-filter-container]')

    const portfolio = document.querySelector('[js-hook-portfolio]')

    if (tabletPortraitAndSmaller.matches) {
        portfolio.classList.remove(CLASS_FILTER_OPEN)
    }

    filterButton.addEventListener('click', () => openFilter())
    
    const openFilter = () => {
        portfolio.classList.toggle(CLASS_FILTER_OPEN)
    }

    ScrollTrigger.create({
        trigger: "[js-hook-hero]",
        start: "bottom 90%",
        endTrigger: portfolio,
        end: 'bottom top',
        onToggle: () => {
            filterContainer.classList.toggle(CLASS_FILTER_MOBILE)
        }
    });
}

export default filter

import gsap from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

const portfolioScroll = () => {
    const portfolio = document.querySelector('[js-hook-portfolio]'); if (!portfolio) return

    gsap.registerPlugin(ScrollToPlugin);

    if (window.location.hash === '#work') {
        gsap.to(window, {duration: 1.5, scrollTo: portfolio, ease: 'power3.inOut'});
    }
}

export default portfolioScroll

import './main.scss'

import barba from '@barba/core';

import lazyLoadPosts from './js/lazy-load'
import pageTransitions from './js/page-transitions'
import videoHover from './js/video-hover'
import menu from './js/menu'
import whatsapp from './js/whatsapp'
import sound from './js/sound'
import menuHover from './js/menu-hover'
import menuMouseMove from './js/menu-mouse-move'
import { tabletLandscapeAndBigger} from './js/utilities/breakpoints'
import scrollButton from './js/scrollButton'
import video from './js/video';
import detectTouch from './js/utilities/detect-touch'
import playHeroVideo from './js/playHeroVideo';
import updateMenuStatus from './js/updateMenuStatus';
import detectAutoplay from './js/detectAutoplay';
import filter from './js/filter'
import previewPlay from './js/previewPlay';

window.addEventListener("load", () => {
    pageTransitions()
    detectTouch()
    lazyLoadPosts()
    videoHover()
    whatsapp()
    sound()
    scrollButton()
    video()
    detectAutoplay('hero-video')
    detectAutoplay('preview-video')
    previewPlay()
    filter()

    menu()
    menuHover()

    if (tabletLandscapeAndBigger.matches) {
        menuMouseMove()
    }

    console.log(
        "%cLike my website? 🚀%cBefore I became a video creator I actually was a web developer for 7 years 🖥️\nHit me up if you would like to talk about websites, I'm always up for it! 📞",
        "color: #ffffff; background-color: #000; font-size: 24px; font-weight: bold; padding: 8px 16px; margin-bottom: 4px;",
        "color: #000000; background-color: #f0f0f0; font-size: 18px; padding: 8px 16px;"
    );
})

barba.hooks.after((data) => {
    detectTouch()
    lazyLoadPosts()
    videoHover()
    whatsapp()
    filter()
    sound()
    scrollButton()
    video()
    playHeroVideo()
    updateMenuStatus(data)
    detectAutoplay('hero-video')
    detectAutoplay('preview-video')
    previewPlay()
})

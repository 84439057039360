import gsap from "gsap";

const videoHover = () => {
    if (window.isTouch) return;
    const portfolioVideos = document.querySelectorAll('[js-hook-portfolio-item'); if (!portfolioVideos) return

    portfolioVideos.forEach(item => {
        item.addEventListener('mouseover', () => handleMouseOver(item))
        item.addEventListener('mouseout', () => handleMouseOut(item))
    })

    const handleMouseOver = (item) => {
        const video = item.querySelector('video')
        const image = item.querySelector('img')
        image && gsap.to(image, {autoAlpha: 0, duration: 0.5})
        
        video.play()
    }

    const handleMouseOut = (item) => {
        const video = item.querySelector('video')
        video.pause()
    }
   
}

export default videoHover

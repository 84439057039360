const detectAutoplay = (hook) => {
    const video = document.querySelector(`[js-hook-${hook}]`); if (!video) return
    const sound = document.querySelector('[js-hook-sound]')
    const previewImage = document.querySelector('[js-hook-preview-image]')

    const CLASS_HIDDEN = 'u-hidden'
    const CLASS_VISIBLE = 'is--visible'

    video.play().then(() => {}).catch((error) => {
        if (previewImage) {
            previewImage.classList.add(CLASS_VISIBLE)
        }
        video.classList.add(CLASS_HIDDEN)
        sound && sound.classList.add(CLASS_HIDDEN)
    });
}

export default detectAutoplay

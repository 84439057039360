import gsap from "gsap/";

const previewPlay = () => {
    const previewVideo = document.querySelector(`[js-hook-preview-video]`); if (!previewVideo) return

    if ( previewVideo.readyState === 4 ) {
        gsap.to(previewVideo, {autoAlpha: 1, duration: 0.6})
    }
}

export default previewPlay

const IS_TOUCH = 'is-touch'
const WITH_MOUSE = `${IS_TOUCH}--with-mouse`
const html = document.documentElement

const detectTouch = () => {
    const hasMouse = false
    const mouseEvent = () => handleMouseEvent()
    const touch =
      'ontouchstart' in html ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0

    html.addEventListener('touchstart', () => {
      html.removeEventListener('mousemove', mouseEvent)
    })

    html.addEventListener('mousemove', mouseEvent)

    if (touch) {
      html.classList.add(IS_TOUCH)
      window.isTouch = true
    }

  const handleMouseEvent = () => {
    if (!hasMouse && html.classList.contains(IS_TOUCH)) {
      html.classList.add(WITH_MOUSE)
      hasMouse = true
    }
  }
}

export default detectTouch

import gsap from "gsap";

const singleEnter = (delay = 0) => {
    const transition = document.querySelector('[js-hook-transition]'); if (!transition) return;

    gsap.set(transition, {x: 0, duration: 1, delay: 1})
    gsap.to(transition, {x: '-100%', duration: 1, ease: 'power4.inOUt', delay})
}

export default singleEnter

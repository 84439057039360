import gsap from "gsap";

const homeToSingleLeave = (current) => {
    gsap.to('[js-hook-logo]', {
        clipPath: 'inset(0 100% 0 0)',
        duration: 1,
        ease: 'power4.inOut'
    })

    return gsap.to(current.container, {opacity: 0.3, duration: 1.5})
}

export default homeToSingleLeave

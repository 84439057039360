const split = (element) => {
    const text = element.innerHTML
    const words = text.split(' ')

    element.innerText = ''

    words.forEach(word => {
        if (word === '<br>') return;
        const chars = word.split('')

        const wordSpan = document.createElement('span')

        chars.forEach(char => {
            const charSpan = document.createElement('span')
            charSpan.innerText = char
            wordSpan.appendChild(charSpan)
            element.appendChild(document.createTextNode(' '))

        })

        element.appendChild(wordSpan)
    })

}

export default split
